<template>
  <div
    class="go-detect px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <img
      src="@/assets/TestEquipment/GoDetectIcon-sm-min.png"
      width="500"
      height="166"
      alt=""
      title="GoDetectIcon-sm"
      class="img-responsive m-auto mb-4"
    />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <a
          class="fusion-no-lightbox"
          href="http://godetect.smsenergetics.com/"
          target="_blank"
          aria-label="GoDetectLink"
          ><img src="@/assets/TestEquipment/CaptureGDH-min.png" alt=""
        /></a>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            GoDetect<sup>TM</sup> (Patent #9,202,145) was developed at Safety
            Management Services, Inc. (SMS) to facilitate reaction detection
            during sensitivity testing with all types of equipment. The software
            solution coupled with a Phantom high-speed camera results in
          </p>
          <ul>
            <li>Significant reduction in test time</li>
            <li>
              Reduction or Elimination of operator subjectivity of reaction
              determination
            </li>
            <li>Immediate replay of test</li>
            <li>High-speed test videos recorded and stored</li>
            <li>
              Quantified reaction results documented (for
              GoDetect-ESD<sup>TM</sup>)
            </li>
            <li>
              Test data that can yield machine variability or drift (for
              GoDetect-ESD<sup>TM</sup>)
            </li>
            <li>
              Test data can be used to obtain material variability or drift (for
              GoDetect-ESD<sup>TM</sup>)
            </li>
            <li>A summary of the reaction probability.</li>
          </ul>
        </Paragraph>
        <div class="text-left">
          <a
            class="link"
            href="http://godetect.smsenergetics.com/"
            target="_blank"
            >Click here to interact with the example application</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "GoDetect Software",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "GoDetect is a patented SMS software designed to facilitate reaction detection during sensitivity testing for all types of equipment."
      }
    ]
  },
  components: {
    Paragraph
  }
};
</script>

<style lang="scss">
.go-detect {
  .link {
    color: #aa222c;
  }
}
</style>
